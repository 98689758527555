import * as React from 'react';
import { FC } from 'react';

interface ShareNavigator extends Navigator {
	share: (data: ShareData) => Promise<void>;
}

declare var navigator: ShareNavigator;

interface ShareData {
	url: string;
	title: string;
	text: string;
}

function getData(): ShareData {
	const canonicalLink: HTMLLinkElement = document.querySelector('link[canonical]') as HTMLLinkElement;
	const url = canonicalLink ? canonicalLink.href : document.location.href;
	const title = document.title;
	const description = document.head.querySelector("meta[property='og:description']") as HTMLMetaElement;
	const text = description ? description.content : '';
	return { url, title, text };
}

const Share: FC = () => {
	if (!navigator.share) {
		return null;
	}

	async function share(): Promise<void> {
		try {
			await navigator.share(getData());
		}
		catch (ex) {
			console.error('failed to share with error', ex);
		}
	}

	return (
		<button onClick={share} aria-label="Share">
			<svg>
				<use xlinkHref="#share-icon" />
			</svg>
		</button>
	);

};

export default Share;
