import * as React from 'react';
import * as reactDom from 'react-dom';
import ShareButton from './generic/components/ShareButton';

(() => {
	const icon = document.getElementById('share');
	if (icon) {
		const jsxElement: JSX.Element = <ShareButton />;
		reactDom.render(jsxElement, icon);
	}
})();
